import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../../../hooks";
import Header from "../../../composants/public/sections/header";
import { GridProperty, HeroProperty, MainContainer, PropertySection, StyledTitle1 } from "../../../styles/atoms";
import CallToAction from "../../../composants/public/sections/callToAction";
import Explore from "../../../composants/public/sections/explore";
import Badge from "../../../composants/public/sections/badge";
import Filter from "../../../composants/public/sections/filter";
import CardUpsell from "../../../composants/public/cards/cardUpsell";
import Footer from "../../../composants/public/sections/footer";
import ScrollButton from "../../../composants/public/widgets/scrollUp";
import MobileBottomHeader from "../../../composants/public/sections/mobileBottomHeader";
import { pubList } from '../../../data/pubList';
import FilterButton from "../../../composants/public/widgets/filterButton";

export default function BestDeals() {
  const { theme } = useTheme();

  // Set document title using useEffect
  useEffect(() => {
    document.title = "BookEasily | Bons Plans";
  }, []);

  return (
    <>
      {/* Header Section */}
      <Header />

      {/* Main Content */}
      <MainContainer theme={theme}>
        {/* Hero Section */}
        <HeroProperty theme={theme}>
          <div className="row">
            <StyledTitle1 theme={theme}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="bi bi-house-check-fill text-warning"></i>
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-white" aria-current="page">
                    Bons Plans
                  </li>
                </ol>
              </nav>
            </StyledTitle1>
          </div>
        </HeroProperty>

        {/* Property Section */}
        <PropertySection>
          <GridProperty>
            {pubList.map(({ id, town, cover, title, price, description }) => (
              <CardUpsell
                key={id}
                id={id}
                town={town}
                title={title}
                price={price}
                cover={cover}
                description={description}
              />
            ))}
          </GridProperty>
        </PropertySection>

        {/* Call to Action Section */}
        <CallToAction />

        {/* Filter Section */}
        <Filter />

        {/* Badge Section */}
        <Badge />

        {/* Explore Section */}
        <Explore />
      </MainContainer>

      {/* Footer and Additional Components */}
      <Footer />
      <FilterButton />
      <ScrollButton />
      <MobileBottomHeader />
    </>
  );
}
