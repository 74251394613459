import { Fragment, useEffect } from "react";
import Header from "../../../composants/public/sections/header";
import { MainContainer, PartnerSection, StyledLink } from "../../../styles/atoms";
import { useTheme } from "../../../hooks";
import Footer from "../../../composants/public/sections/footer";
import MobileBottomHeader from "../../../composants/public/sections/mobileBottomHeader";
import HelpButton from "../../../composants/public/widgets/helpButton";
import img from '../../../assets/bookeasily_partners.png';

function Partners() {
  const { theme } = useTheme();
  const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;

  useEffect(() => {
    document.title = 'BookEasily | Devenez partenaire';
    const desc = document.querySelector('meta[name="description"]');
    if (desc) {
      desc.setAttribute('content', 'BookEasily | Devenez partenaire et boostez votre visibilité et vos réservations');
    }
  }, []);

  const getLink = (path) => (user ? `/dashboard/categories/${path}/new_ads` : '/sign-in');

  return (
    <Fragment>
      <Header />
      <MainContainer theme={theme}>
        <PartnerSection>
          <div className="card my-5 my__card">
            <div className="row d-flex flex-row align-items-center g-0">
              <div className="col-lg-4">
                <img src={img} className="img-fluid rounded-start" alt="BookEasily" />
              </div>
              <div className="col-lg-8">
                <div className="card-body">
                  <h3 className="card-title fw-bold pb-2">
                    Rejoignez la communauté <span className="text-warning">BookEasily</span> !
                  </h3>
                  <p className="card-text">
                    <span className="fw-bold">Propriétaires immobiliers et hôteliers</span>, augmentez votre <span className="fw-bold">visibilité et vos réservations</span> en publiant vos biens sur BookEasily, une plateforme de réservation en ligne au Cameroun et en Afrique.
                  </p>
                  <p className="card-text fw-bold">Facile et rapide :</p>
                  <ul>
                    <li>Créez votre compte en moins d'une minute,</li>
                    <li>Ajoutez vos biens avec des photos et descriptions attractives,</li>
                    <li>Fixez vos prix et votre calendrier de disponibilité, et</li>
                    <li>Commencez à recevoir des réservations</li>
                  </ul>
                </div>
                <div className="card-footer bg-white border-top border-white">
                  <div className="btn-group btn btn-warning rounded-5 btn-md px-4 fw-bold">
                    <button className="btn p-0 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="true">
                      <i className="bi bi-bag-plus"></i> <StyledLink className="fw-bold text-dark">Ajoutez votre annonce</StyledLink>
                    </button>
                    <ul className="dropdown-menu">
                      <li className="dropdown-item">
                        <StyledLink to={getLink('hotels')}><i className="bi bi-buildings"></i> Hotel</StyledLink>
                      </li>
                      <li className="dropdown-item">
                        <StyledLink to={getLink('meubles')}><i className="bi bi-building"></i> Meublé</StyledLink>
                      </li>
                      <li className="dropdown-item">
                        <StyledLink to={getLink('non meubles')}><i className="bi bi-house-door"></i> Location/Non Meublé</StyledLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PartnerSection>
      </MainContainer>
      <Footer />
      <HelpButton />
      <MobileBottomHeader />
    </Fragment>
  );
}

export default Partners;
