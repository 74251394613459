import React, { Fragment, useState } from 'react'
import { Link  } from 'react-router-dom'
import 'react-phone-number-input/style.css'
import { useFetch33, useTheme } from '../../../hooks'
import Error from '../../../composants/public/widgets/error'
import Loading from '../../../composants/public/widgets/loading'
import { GridBoardForm, HeroSection, HomeLogo, LogSection, MainContainer, StyledLink, StyledTitle2 } from '../../../styles/atoms'
import PhoneInput from 'react-phone-number-input/input'
import Header from '../../../composants/public/sections/header'
import Footer from '../../../composants/public/sections/footer'
import MobileBottomHeader from '../../../composants/public/sections/mobileBottomHeader'
import logo from '../../../assets/logo.png'
import HelpButton from '../../../composants/public/widgets/helpButton'
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const ItemType = 'IMAGE';

function CreateLocation () {
    document.title = `BookEasily | Ajoutez un nouveau bien immobilier - Non Meublé`
    const x_xsrf_token = localStorage.getItem("cookies")
    const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null

    const { theme } = useTheme()
    const [title, setTitle] = useState('')
    const [subtitle, setSubTitle] = useState('')
    const [description, setDescription] = useState('')
    const [type, setType] = useState('')
    const [hosting_manager, setHostingManager] = useState('')
    const [country, setCountry] = useState('')
    const [street_id, setStreetId] = useState('')
    const [value, setValue] = useState('')
    const [value2, setValue2] = useState('')
    const [prices_month, setPriceMonth] = useState('')
    const [boite, setBoite] = useState('')
    const [n_rooms, setNRooms] = useState(1)
    const [smoke, setSmoke] = useState(false)
    const [animal, setAnimal] = useState(false)
    const [n_kitchens, setNKitchens] = useState(0)
    const [showers, setShowers] = useState(0)
    const [n_living, setNLiving] = useState(0)
    const [total_area, setTotalArea] = useState(0)
    const [quantity, setQuantity] = useState(1)
    const [access, setAccess] = useState(false)
    const [zone, setZone] = useState(false)
    const [plage, setPlage] = useState(false)
    const [parking, setParking] = useState(false)
    const [with_disability, setWithDisability] = useState(false)
    const [check_in_start, setCheckInStart] = useState('')
    const [check_out_start, setCheckOutStart] = useState('')
    const [check_in_end, setCheckInEnd] = useState('')
    const [check_out_end, setCheckOutEnd] = useState('')
    const [status, setStatus] = useState('')
    const [advance_in_months, setAvanceInMonth] = useState(0)
    const [deposit_in_months, setDepositInMonth] = useState(0)
    const [load, setLoad] = useState(0)
    const [data_error, setDataError] = useState('')
    const [position, setPosition] = useState('')
    const [town, setTown] = useState('')
    const [streets, setStreets] = useState('')

    let Allprices = {}
    if (prices_month) {
        Allprices.months = prices_month
    }

    const { isLoading33, data31, data32, data33, error33 } = useFetch33(
        `https://api.book-easily.com/api/countries`,
        `https://api.book-easily.com/api/towns`,
        `https://api.book-easily.com/api/categories/3/types`,
        token
    )
    const countries = data31?.data
    const towns = data32?.data
    const types = data33?.data

    async function handleStreets (e, token, x_xsrf_token){
        e.preventDefault()
        await fetch(`https://api.book-easily.com/api/towns/${e.target.value}/streets`, {
            method: "GET",
            mode: "cors",
            cache: "default",
            credentials: "include",
            
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json; charset=UTF-8',
              'Accept': 'application/json',
              'X-XSRF-TOKEN': `${x_xsrf_token}`
            }, 
          })
          .then((response) => response.json())
          .then((data) => {
            if (data?.status === 1) {
                setStreets(data?.data)
                setTown(e.target.value)
            }
          })
          .catch((err) => {
            console.log(err.message)
          })
    }

    const forms = new FormData()
    forms.append('title', title)
    forms.append('type_id', type)
    forms.append('category_id', 3)
    forms.append('street_id', street_id)
    forms.append('n_bedrooms', n_rooms)
    forms.append('total_area', total_area)
    forms.append('description', description)
    forms.append('country_id', country)
    forms.append('quantity', quantity)
    forms.append('designation', subtitle)
    forms.append('prices', JSON.stringify(Allprices))
    forms.append('check_in_start', check_in_start)
    forms.append('check_out_start', check_out_start)
    forms.append('check_in_end', check_in_end)
    forms.append('check_out_end', check_out_end)
    forms.append('advance_in_months', advance_in_months)
    forms.append('deposit_in_months', deposit_in_months)

    n_kitchens !== 0 && forms.append('n_kitchens', n_kitchens)
    showers !== 0 && forms.append('n_showers', showers)
    n_living !== 0 && forms.append('n_living_rooms', n_living)
    value && forms.append('manager_phone', value)
    value2 && forms.append('manager_phone2', value2)
    hosting_manager && forms.append('manager_full_name', hosting_manager)
    boite && forms.append('post_boxe', boite)
    position && forms.append('map', JSON.stringify(position))

    animal === true ? forms.append('with_animals', 1) : forms.append('with_animals', 0)
    smoke === true ? forms.append('can_smoke', 1) : forms.append('can_smoke', 0)
    zone === true ? forms.append('near_tourist_area', 1) : forms.append('near_tourist_area', 0)
    plage === true ? forms.append('near_beach', 1) : forms.append('near_beach', 0)
    parking === true ? forms.append('free_parking', 1) : forms.append('free_parking', 0)
    access === true ? forms.append('practical_access', 1) : forms.append('practical_access', 0)
    with_disability === true ? forms.append('with_disability', 1) : forms.append('with_disability', 0)

    const [photos, setPhotos] = useState([]);
    const [previewPhotos, setPreviewPhotos] = useState([]);

    const upload = (e) => {
        const files = Array.from(e.target.files);
        if (files.length > 10) {
            alert('Vous pouvez sélectionner un maximum de 10 images.');
            return;
        }
        setPhotos(files);
        setPreviewPhotos(files.map(file => URL.createObjectURL(file)));
    };

    const moveImage = (dragIndex, hoverIndex) => {
        const newPhotos = [...photos];
        const newPreviewPhotos = [...previewPhotos];
        const [draggedPhoto] = newPhotos.splice(dragIndex, 1);
        const [draggedPreview] = newPreviewPhotos.splice(dragIndex, 1);
        newPhotos.splice(hoverIndex, 0, draggedPhoto);
        newPreviewPhotos.splice(hoverIndex, 0, draggedPreview);
        setPhotos(newPhotos);
        setPreviewPhotos(newPreviewPhotos);
    };
     
    async function handleSubmit(e, forms, token, x_xsrf_token) {
        e.preventDefault() 
        setLoad(1)

        if (photos) {
            if (photos.length < 10) {
                for (let i = 0; i < photos.length; i++) {
                    forms.append('images[]', photos[i])
                }
            } else {
                for (let i = 0; i < 10; i++) {
                    forms.append('images[]', photos[i])
                }
            }
        }

        await fetch('https://api.book-easily.com/api/ads', {
            method: "POST",
            mode: "cors",
            cache: "default",
            credentials: "include",
            redirect: 'manual',
            body: forms,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json; charset=UTF-8',
                'X-XSRF-TOKEN': `${x_xsrf_token}`,
            },
        })
        .then((response) => response.json())
        .then((data) => {
            setStatus(data?.status)
            setLoad(0)
            if (data?.status === 1) {
                window.location.assign("/dashboard") 
            } else {
                setDataError(data?.errors)
            }
        })
        .catch((err) => {
            setLoad(0)
            console.log(err.message)
        })
    } 

     if (error33) {
        return (
          <Error />
        )
      }

    return isLoading33 ? (
        <Loading />
        ) : (
            user.role_id === 3 ?
            <Fragment>
                
                { user.email_verified === 0 && 
                    (<div className="alert alert-danger text-center" role="alert">
                        Votre compte n'est vérifié, consultez vos mails pour le faire ou contactez le service clients !
                    </div>)
                }
                <LogSection>
                    <form className="my__form g-2 p-3 my-5 col-md-8" method="POST" onSubmit={(e) => handleSubmit(e, forms, token, x_xsrf_token)}>
                        <div className="col-md-12 text-center">
                            <Link to="/dashboard">
                                <HomeLogo src={logo} alt="logo BookEasily" />
                            </Link>
                        </div>
                        <div className="col-md-12 my-1">
                            <StyledTitle2 className='text-black'>Ajoutez une nouvelle annonce | Location</StyledTitle2>
                        </div>
                        { status === 1 && 
                            (<div className="alert alert-info text-center" role="alert">
                                Votre annonce a été crée avec succès ! Voir votre <Link to='/dashboard' >tableau de bord</Link>. 
                            </div> )
                        }
                        <div className="col-md-12 my-1">
                            <i className="bi bi-box-arrow-up-left text-warning"></i> <Link to="/dashboard" className='text-warning'> Accueil</Link>
                        </div>
                        <GridBoardForm>
                            <div className="col">
                                <input type="text" className="form-control rounded-5" id="subtitle" name="subtitle" maxLength={50} value={subtitle} placeholder="Nom de l'hébergement..." onChange={(e) => setSubTitle(e.target.value) } required/>
                                <small className="text-muted">Nom de l'hébergement *</small>
                                { data_error.designation && 
                                    (<div className="alert alert-danger text-center my-1" role="alert">
                                        {data_error.designation}
                                    </div>)
                                }
                            </div>
                            <div className="col">
                                <input type="text" className="form-control rounded-5" id="title" name="title" maxLength={150} value={title} placeholder="Decrire votre logement en quelques mots..." onChange={(e) => setTitle(e.target.value) } required/>
                                <small className="text-muted">Courte description *</small>
                                { data_error.name && 
                                    (<div className="alert alert-danger text-center my-1" role="alert">
                                        {data_error.name}
                                    </div>)
                                }
                            </div>
                            <div className="col">
                                <select className="form-select rounded-5" id="type" name="type" onChange={(e) => setType(e.target.value) } required>
                                    <option value=''>Type de logement...</option>
                                    {types.map((type) => (
                                    <option key={ `${type.name}-${type.id}` } value={type.id}>{type.name}</option>
                                    ))}
                                </select>
                                <small className="text-muted">Type de logement *</small>
                                { data_error.type && 
                                    (<div className="alert alert-danger text-center my-1" role="alert">
                                        {data_error.type}
                                    </div>)
                                }
                            </div>
                            <div className="col">
                                <input type="number" className="form-control rounded-5" id="quantity" name="quantity" min='1' value={quantity} onChange={(e) => setQuantity(e.target.value) } placeholder="Quantité à publier sur BookEasily" required/>
                                <small className="text-muted">Quantité de logement à publier sur BookEasily *</small>
                                { data_error.quantity && 
                                    (<div className="alert alert-danger text-center my-1" role="alert">
                                        {data_error.quantity}
                                    </div>)
                                }
                            </div>
                            <div className="col">
                                <input type="text" className="form-control rounded-5" id="hosting_manager" name="hosting_manager" value={hosting_manager} placeholder="Nom du responsable..." onChange={(e) => setHostingManager(e.target.value) }/>
                                <small className="text-muted">Nom du responsable</small>
                                { data_error.manager_full_name && 
                                    (<div className="alert alert-danger text-center my-1" role="alert">
                                        {data_error.manager_full_name}
                                    </div>)
                                }
                            </div>
                            <div className="col">
                                <PhoneInput id="phone" name="phone" className="form-control rounded-5"
                                    placeholder="Indicatif de votre pays + numéro de la reception..."
                                    defaultCountry='CM'
                                    value={value}
                                    onChange={setValue} required/>
                                <small className="text-muted">Numéro Whatsapp de la reception</small>
                                { data_error.manager_phone && 
                                    (<div className="alert alert-danger text-center my-1" role="alert">
                                        {data_error.manager_phone}
                                    </div>)
                                }
                            </div>
                            <div className="col">
                                <PhoneInput id="phone2" name="phone2" className="form-control rounded-5"
                                    placeholder="Indicatif de votre pays + numéro de la reception..."
                                    defaultCountry='CM'
                                    value={value2}
                                    onChange={setValue2} />
                                <small className="text-muted">Autre numéro de la reception</small>
                                { data_error.manager_phone2 && 
                                    (<div className="alert alert-danger text-center my-1" role="alert">
                                        {data_error.manager_phone2}
                                    </div>)
                                }
                            </div>
                        </GridBoardForm>
                        
                        <hr className="text-warning"/>
        
                        <fieldset className='my-2 gy-5'>
                            <GridBoardForm>
                                <div className="col-sm-12 py-1">
                                    <select className="form-select rounded-5" id="country" name="country" onChange={(e) => setCountry(e.target.value) } required>
                                        <option value=''>Pays...</option>
                                        {countries.map((country) => (
                                        <option key={ `${country.name}-${country.id}` } value={country.id}>{country.name}</option>
                                        ))}
                                    </select>
                                    <small className="text-muted">Pays *</small>
                                    { data_error.country && 
                                        (<div className="alert alert-danger text-center my-1" role="alert">
                                            {data_error.country}
                                        </div>)
                                    }
                                </div>
                                <div className="col-sm-12 py-1">
                                    <select className="form-select rounded-5" id="town" name="town" onChange={(e) => handleStreets(e, token, x_xsrf_token) } required>
                                        <option value=''>Ville...</option>
                                        {towns.map((town) => (
                                            <option key={ `${town.town}-${town.id}` } value={town.id}>{town.town}</option>
                                        ))}
                                    </select>
                                    <small className="text-muted">Ville *</small>
                                </div>

                                { town !== '' &&
                                    <div className="col-sm-12 py-1">
                                        <select className="form-select rounded-5" id="street" name="street" onChange={(e) => setStreetId(e.target.value) } required>
                                            <option value=''>Quartier...</option>
                                            {streets.map((street) => (
                                            <option key={ `${street.name}-${street.id}` } value={street.id}>{street.name}</option>
                                            ))}
                                        </select>
                                        <small className="text-muted">Quartier *</small>
                                        { data_error.street && 
                                            (<div className="alert alert-danger text-center my-1" role="alert">
                                                {data_error.street}
                                            </div>)
                                        }
                                    </div>
                                }
                                <div className="col-sm-12 py-1">
                                    <input type="url" className="form-control rounded-5" id="position" name="position" value={position} placeholder="lien map (Ex: https://...)" onChange={(e) => setPosition(e.target.value) }/>
                                    <small className="text-muted">Lien Map (Ex: https://...)</small>
                                </div>
                                <div className="col-sm-12 py-1">
                                    <input type="text" className="form-control rounded-5" id="boite" name="boite" value={boite} placeholder="Boite postale..." onChange={(e) => setBoite(e.target.value) } />
                                    <small className="text-muted">Boite postale</small>
                                    { data_error.post_boxe && 
                                        (<div className="alert alert-danger text-center my-1" role="alert">
                                            {data_error.post_boxe}
                                        </div>)
                                    }
                                </div>
                            </GridBoardForm>
                            <GridBoardForm>
                                <div className="form-check py-1">
                                    <input className="form-check-input" type="checkbox" checked={access} id="access" name='access' onChange={(e) => setAccess(e.target.checked) } />
                                    <label className="form-check-label" htmlFor="access">
                                        Accès praticable
                                    </label>
                                </div>
                                <div className="form-check py-1">
                                    <input className="form-check-input" type="checkbox" checked={with_disability} id="with_disability" name='with_disability' onChange={(e) => setWithDisability(e.target.checked) } />
                                    <label className="form-check-label" htmlFor="with_disability">
                                        Accessible aux handicapés
                                    </label>
                                </div>
                                <div className="form-check py-1">
                                    <input className="form-check-input" type="checkbox" checked={zone} id="zone" name='zone' onChange={(e) => setZone(e.target.checked) }/>
                                    <label className="form-check-label" htmlFor="zone">
                                        Proche d'un site tourristique
                                    </label>
                                </div>
                                <div className="form-check py-1">
                                    <input className="form-check-input" type="checkbox" checked={plage} id="plage" name='plage' onChange={(e) => setPlage(e.target.checked) }/>
                                    <label className="form-check-label" htmlFor="plage">
                                        Proche d'une plage ?
                                    </label>
                                </div>
                            </GridBoardForm>
                        </fieldset>
        
                        <hr className="text-warning"/>
                        
                        <fieldset className='my-2 gy-5'>
                            <GridBoardForm>
                                { ( type === '15' || type === '16' || type === '18' || type === '19') ? (
                                    <Fragment>
                                    <div className="col-sm-12 py-1">
                                        <input type="number" className="form-control rounded-5" id="n_living" name="n_living" value={n_living} min='0' placeholder="Nombre de salons..." onChange={(e) => setNLiving(e.target.value) } />
                                        <small className="text-muted">Nombre de salons</small>
                                    </div>
                                    <div className="col-sm-12 py-1">
                                        <input type="number" className="form-control rounded-5" id="n_rooms" name="n_rooms" value={n_rooms} min='1' placeholder="Nombre de chambres..." onChange={(e) => setNRooms(e.target.value) } />
                                        <small className="text-muted">Nombre de chambres</small>
                                        { data_error.n_bedrooms && 
                                            (<div className="alert alert-danger text-center my-1" role="alert">
                                                {data_error.n_bedrooms}
                                            </div>)
                                        }
                                    </div>
                                    <div className="col-sm-12 py-1">
                                        <input type="number" className="form-control rounded-5" id="n_kitchens" name="n_kitchens" value={n_kitchens} min='0' placeholder="Nombre de cuisines..." onChange={(e) => setNKitchens(e.target.value) } />
                                        <small className="text-muted">Nombre de cuisines</small>
                                    </div>
                                    </Fragment>
                                ) : null }
                                <div className="col-sm-12 py-1">
                                    <input type="number" className="form-control rounded-5" id="showers" name="showers" value={showers} min='0' placeholder="Nombre de douches..." onChange={(e) => setShowers(e.target.value) } />
                                    <small className="text-muted">Nombre de douches</small>
                                </div>
                                <div className="col-sm-12 py-1">
                                    <input type="number" className="form-control rounded-5" id="total_area" name="total_area" min='0' value={total_area} placeholder="Espace total en mettre carré..." onChange={(e) => setTotalArea(e.target.value) }/>
                                    <small className="text-muted">Espace total en metre carré</small>
                                </div>
                            </GridBoardForm>
                            <div className="col-12 py-1">
                                <textarea rows='10' className='form-control' value={description} id='description' name='description' placeholder="Description de votre immobilier, lieu exact de l'hebergement, lieu attractif, les avantages, etc..." onChange={(e) => setDescription(e.target.value) } required></textarea>
                                <small className="text-muted">Description de votre immobilier : Ne pas mettre de photo, de numero de telephone et d'email *</small>
                                { data_error.description && 
                                    (<div className="alert alert-danger text-center my-1" role="alert">
                                        {data_error.description}
                                    </div>)
                                }
                            </div>
                            <GridBoardForm>
                                <div className="form-check py-1">
                                    <input className="form-check-input" type="checkbox" checked={parking} id="parking" name='parking' onChange={(e) => setParking(e.target.checked) }/>
                                    <label className="form-check-label" htmlFor="parking">
                                        Parking gratuit
                                    </label>
                                </div>
                                <div className="form-check py-1">
                                    <input className="form-check-input" type="checkbox" checked={smoke} id="smoke" name='smoke' onChange={(e) => setSmoke(e.target.checked) }/>
                                    <label className="form-check-label" htmlFor="smoke">
                                        Autorisation de fumer
                                    </label>
                                </div>
                                <div className="form-check py-1">
                                    <input className="form-check-input" type="checkbox" checked={animal} id="animal" name='animal' onChange={(e) => setAnimal(e.target.checked) }/>
                                    <label className="form-check-label" htmlFor="animal">
                                        Animaux acceptés
                                    </label>
                                </div>
                            </GridBoardForm>
                            <GridBoardForm>
                                <div className="col-sm-12 py-1">
                                    <div className="input-group">
                                        <div className="input-group-text">De</div>
                                        <input type="time" className="form-control" id="check_in" name="check_in_start" value={check_in_start} onChange={(e) => setCheckInStart(e.target.value) }/>
                                    </div>
                                    <small className="text-muted">Heure d'arrivée des visites</small>
                                </div>
                                <div className="col-sm-12 py-1">
                                    <div className="input-group">
                                        <div className="input-group-text">A</div>
                                        <input type="time" className="form-control" id="check_in_end" name="check_in_out" value={check_in_end} onChange={(e) => setCheckInEnd(e.target.value) } />
                                    </div>
                                    <small className="text-muted">Heure d'arrivée des visites</small>
                                </div>
                                <div className="col-sm-12 py-1">
                                    <div className="input-group">
                                        <div className="input-group-text">De</div>
                                        <input type="time" className="form-control" id="check_out_start" name="check_out_start" value={check_out_start} onChange={(e) => setCheckOutStart(e.target.value) }/>
                                    </div>
                                    <small className="text-muted">Heure de fin des visites</small>
                                </div>
                                <div className="col-sm-12 py-1">
                                    <div className="input-group">
                                        <div className="input-group-text">A</div>
                                        <input type="time" className="form-control" id="check_out_end" name="check_out_end" value={check_out_end} onChange={(e) => setCheckOutEnd(e.target.value) } />
                                    </div>
                                    <small className="text-muted">Heure de fin des visites</small>
                                </div>
                            </GridBoardForm>
                            <DndProvider backend={HTML5Backend}>
                                <div>
                                    <div className="col-12 py-1">
                                        <input 
                                            type="file" 
                                            accept="image/*" 
                                            className="form-control rounded-5" 
                                            id="photo" 
                                            name="photo" 
                                            multiple 
                                            onChange={upload} 
                                            required 
                                        />
                                        <small className="text-warning">Maximum 10 images *</small>
                                    </div>
                                    <p>Choisissez votre image principale</p>
                                    <div className="preview-container">
                                        {previewPhotos.map((src, index) => (
                                            <ImagePreview 
                                                key={index} 
                                                src={src} 
                                                index={index} 
                                                moveImage={moveImage} 
                                            />
                                        ))}
                                    </div>
                                </div>
                            </DndProvider>
                        </fieldset>
        
                        <hr className="text-warning"/>
                        
                        <fieldset className='my-2 gy-5'>
                            <GridBoardForm>
                                <div className="col-sm-12 py-1">
                                    <input type="number" className="form-control rounded-5" id="prices_month" value={prices_month} min='0' name="prices_month" placeholder="Tarif mensuel" onChange={(e) => setPriceMonth(e.target.value)} required/>
                                    <small className="text-muted">Frais de location par mois *</small>
                                </div>
                                <div className="col-sm-12 py-1">
                                    <input type="number" className="form-control rounded-5" id="n_month" value={advance_in_months} min='0' name="n_month" placeholder="Nombre de mois à l'avance..." onChange={(e) => setAvanceInMonth(e.target.value)} required/>
                                    <small className="text-muted">Nombre de mois à l'avance *</small>
                                </div>
                                <div className="col-sm-12 py-1">
                                    <input type="number" className="form-control rounded-5" id="n_caution" value={deposit_in_months} min='0' name="n_caution" placeholder="Nombre de mois de caution..." onChange={(e) => setDepositInMonth(e.target.value)} required/>
                                    <small className="text-muted">Nombre de mois de caution *</small>
                                </div>
                                
                            </GridBoardForm>
                            
                        </fieldset>
        
                        <hr className="text-warning"/>

                        <div className="form-check py-3">
                            <input className="form-check-input" type="checkbox" id="al" name='al'  required/>
                            <label className="form-check-label" htmlFor="al">
                                J'atteste qu'il s'agit d'une activité légale et que je suis le propriétaire/gérant légal de cet hébergement *
                            </label>
                        </div>
                        <div className="form-check py-2">
                            <input className="form-check-input" type="checkbox" id="cg" name='cg' required/>
                            <label className="form-check-label" htmlFor="cg">
                                J'ai lu et j'accepte les conditions générales de ventes *
                            </label>
                        </div>
                        <div className="col-12 my-1 text-warning">
                            * = champ obligatoire
                        </div>
                        <div className="col-12 my-1 text-end">
                            <StyledLink theme={theme} to={ `/about#contact` } >Besoin d'aide ?</StyledLink>
                        </div>

                        { status === 0 && 
                            (<div className="alert alert-danger text-center" role="alert">
                                Vérifiez à nouveau vos informations. 
                            </div>)
                        }

                        <div className="col-12">
                            { load === 0 ? 
                                <button type="submit" className="btn btn-outline-warning fw-bold w-100 my-3 rounded-5">{ status === 1 ? <span>Opération réussie ✔</span>  : <span>Créez votre annonce</span>  }</button>
                            :
                                <button className="btn btn-outline-warning fw-bold w-100 my-3 rounded-5" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> En cours...
                                </button>
                            }
                        </div>
                    </form>
                </LogSection> 
                <HelpButton />
            </Fragment>
            :
            <Fragment>
            <Header />
            <MainContainer theme={theme}>
                <HeroSection>
                    <div className="py-4 text-center text-white">
                        <h2 className="display-5 fw-bold">Impossible !</h2>
                        <div className="col-lg-6 mx-auto">
                            <p className="lead mb-4">Vous devez avoir un compte partenaire pour ajouter une annonce</p>
                            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                                <button type="button" to="/sign-up" className="btn btn-warning btn-md fw-bold px-4">Créer un compte partenaire</button>
                            </div>
                        </div>
                    </div>
                </HeroSection>
            </MainContainer>
            <Footer />
            <HelpButton />
            <MobileBottomHeader/>
            </Fragment>
        )
}

const ImagePreview = ({ src, index, moveImage }) => {
    const ref = React.useRef(null);

    const [, drop] = useDrop({
        accept: ItemType,
        hover(item) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            moveImage(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemType,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <div 
            ref={ref} 
            className="preview-item" 
            style={{ opacity: isDragging ? 0.5 : 1 }}
        >
            <img src={src} alt={`preview ${index}`} className="preview-image" />
        </div>
    );
};

export default CreateLocation