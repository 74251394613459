import { Fragment, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { useFetch, useTheme } from "../../../hooks"
import Error from "../../../composants/public/widgets/error"
import Header from "../../../composants/public/sections/header"
import { HeroProperty, ImgCardSingle, MainContainer, StyledLink, StyledTitle1, UserProfil } from "../../../styles/atoms"
import Loading from "../../../composants/public/widgets/loading"
import Badge from "../../../composants/public/sections/badge"
import CallToAction from "../../../composants/public/sections/callToAction"
import Filter from "../../../composants/public/sections/filter"
import Explore from "../../../composants/public/sections/explore"
import Footer from "../../../composants/public/sections/footer"
import ScrollButton from "../../../composants/public/widgets/scrollUp"
import CheckButton from "../../../composants/public/widgets/checkButtom"
import MobileBottomHeader from "../../../composants/public/sections/mobileBottomHeader"
import FilterButton from "../../../composants/public/widgets/filterButton"


function Property () {
  const { param_cat, param_name, param_id } = useParams()
  document.title = `BookEasily | Consultez ${param_name} - ${param_cat}`
  const x_xsrf_token = localStorage.getItem("cookies")

  const { theme } = useTheme()
  // const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
  const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null
  const [annonce, setAnnonce] = useState()
  const [prices, setPrices] = useState({days: 0, weeks: 0, months: 0})
  const [covers, setCovers] = useState()
  const [start_date, setStartDate] = useState()
  const [end_date, setEndDate] = useState()
  const [number_persons, setNumberPersons] = useState()
  const [availability, setAvailability] = useState(2)
  const [total_price, setTotalPrice] = useState()
  const [duration, setDuration] = useState()
  const [check, setCheck] = useState()
  const [status, setStatus] = useState()
  const [load, setLoad] = useState(0)
  const [coupon7, setCoupon7] = useState(0)
  const [coupon30, setCoupon30] = useState(0)

  useEffect(() => {
    const getData = async () => {
      const response = await fetch(
        `https://api.book-easily.com/api/ads/${param_id}`, {
          method: "GET",
          mode: "cors",
          cache: "default",
          credentials: "include",
          headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json',
              "Content-Type": "application/json; charset=UTF-8",
          }
        }
      )
      const data = await response.json()
      if (data?.status === 1) {
        setAnnonce(data?.data)
        setPrices(data?.data?.prices)
        setCovers(data?.data?.cover)
      }
    }
    getData()
  }, [param_id, token])

  useEffect(() => {
    prices.weeks > 0 && setCoupon7(Math.round(((prices.days - (prices.weeks/7))/prices.days)*100))
    prices.months > 0 && setCoupon30(Math.round(((prices.days - (prices.months/30))/prices.days)*100))
  }, [prices] )

  const settings = {
    customPaging: function(i) {
      return (
        <div className="row">
          <Link className="col slider-container">
            <img src={`${covers[i]}`} className="rounded-1" loading="lazy"  alt="BookEasily" width="20px" height="20px" />
          </Link>
        </div>
      )
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  }

  async function handleCheck (e, param_id, start_date, end_date, number_persons) {
    e.preventDefault()
    setLoad(1)

    await fetch(`https://api.book-easily.com/api/ads/${param_id}?start_date=${start_date}&end_date=${end_date}&number_persons=${number_persons}`, {
      method: 'GET',
      mode: "cors",
      cache: "default",
      credentials: "include",
      headers: {
         'Authorization': `Bearer ${token}`,
         'Content-Type': 'application/json; charset=UTF-8',
         'Accept': 'application/json',
         'X-XSRF-TOKEN': `${x_xsrf_token}`
      },
   })
   .then((response) => response.json())
   .then((data) => {
     setStatus(data?.status)
     setLoad(0)
     if (data?.status === 1) {
       setCheck(data?.data)
       setAvailability(data?.data.availability)
       setTotalPrice(data?.data.total_price)
       setDuration(data?.data.duration)
     }
   })
   .catch((err) => {
    setLoad(0)
     console.log(err.message)
   })
  }

  function handleCheckOut() {
      window.location.assign(`/checkout/${annonce.id}/${start_date}/${end_date}/${number_persons}/${check.n_pieces}/${total_price}/${annonce.designation}/${annonce.category}/${annonce.manager_phone}/${annonce.type}/${annonce.town}/${annonce.street}/${duration}`)
  }

  const { isLoading11, data11, error11 } = useFetch(
    `https://api.book-easily.com/api/ads?per_page=15`,
    token
  )
  let countAds = data11?.meta?.total
  
  if (error11) {
    return (
      <Error />
      )
  }

    return (
      <Fragment>

        {/* Header Section */}
          <Header/>

          <MainContainer theme={theme}>
          {/* { user.email_verified === 0 && 
              (<div className="alert alert-danger text-center" role="alert">
                  Votre compte n'est vérifié, consultez vos mails pour le faire ou contactez le service clients !
              </div>)
          } */}
            {/* Hero Section */}
            <HeroProperty theme={theme}>
              <div className="row">
                <StyledTitle1 theme={theme}>
                  <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                          <li className="breadcrumb-item"><Link to="/"><i className="bi bi-house-check-fill text-warning"></i></Link></li>
                          <li className="breadcrumb-item text-warning text-capitalize">{param_cat}</li>
                          {(() => {
                              const arr = param_name && param_name.split('_').join(' ');
                              return (
                                <li className="breadcrumb-item text-white" aria-current="page">{arr}</li>
                              )
                          })()}
                      </ol>
                  </nav>
                </StyledTitle1>
              </div>
            </HeroProperty>

            {isLoading11 ? (
            <Loading />
            ) : (
            <Fragment>
              {/* Property Section */}
              <div className="container-fluid px-lg-5">
                <div className="row mt-1 mb-5 g-3">
                  <div className="col-md-6 slider-container">
                    <Slider {...settings}>
                        {covers.map(image => (
                            <ImgCardSingle key={image.id} src={ image } loading="lazy" className="card-img img-fluid rounded" alt={annonce.name} />
                        ))} 
                    </Slider>
                  </div>
                  <div className="col-md-6">
                    <h3 className="display fw-bold mt-4 mb-3 mt-md-0">
                      {annonce.designation} { annonce.standing && 
                          <Fragment>
                          {(() => {
                            const arr = [];
                            for (let i = 0; i < annonce.standing; i++) {
                              arr.push(
                                  '⭐'
                              );
                            }
                            return arr;
                          })()}
                          </Fragment>
                      }
                    </h3>
                    <span className='fs-10 text-capitalize'>
                      <UserProfil src={ `https://api.book-easily.com/${annonce.user_photo_url}` } alt={annonce.user_first_name} />
                      <span className='fs-10 text-capitalize'> {annonce.user_first_name}</span>
                    </span>
                    <div className="row row-cols-1 row-cols-sm-2 g-2 my-1">
                    {coupon7 > 0 &&
                      <p className="fw-bold text-danger"><i class="bi bi-tags-fill"></i> { coupon7 } % de réduction à partir de 7 jours</p>
                    }
                    {coupon30 > 0 &&
                      <p className="fw-bold text-danger"><i class="bi bi-tags-fill"></i> { coupon30 } % de réduction à partir de 30 jours</p>
                    }
                    </div>
                    <p className="lead-3 mb-2 fw-normal">{annonce.name}</p>
                    {(() => {
                        let arr = annonce.designation && annonce.designation.split(' ').join('_');
                        arr = arr && arr.split('&').join('_');
                        arr = arr && arr.split('/').join('_');
                        return (
                          <div className="d-flex justify-content-between">
                            <div>
                              Partager :
                            </div>
                            <div>
                              </div>
                                <div className="row">
                                  <Link className="col-1 lead" to={`https://api.whatsapp.com/send?text=${annonce.type}%20-%20${annonce.category}%20de%20${arr}%20à%20${annonce.town}%20-%20${annonce.street}%20disponible%20sur%20BookEasily.%20Cliquez%20sur%20le%20lien%20suivant%20pour%20consulter%20et%20effectuer%20votre%20reservation%20:%20https://www.book-easily.com/${annonce.category}/property/${arr}/${annonce.id}`} target="_blank">
                                      <i className="bi bi-whatsapp text-success"></i>
                                  </Link>
                                  <div className="col-1 lead fb-share-button" data-href={`https://www.book-easily.com/${annonce.category}/property/${arr}/${annonce.id}`} data-layout="button" data-size="small">
                                    <Link target="_blank" to={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.book-easily.com/${annonce.category}/property/${arr}/${annonce.id}&src=sdkpreparse`} className="fb-xfbml-parse-ignore">
                                        <i className="bi bi-facebook"></i>
                                    </Link>
                                  </div>
                                </div>
                            </div>
                        )
                    })()}
                    <hr />
                    <div className="row row-cols-2 gy-1">
                      <p className="lead-3 text-capitalize border-bottom"><i className="bi bi-geo-alt-fill text-danger"></i> {annonce.town} - {annonce.street} </p>
                      <p className="lead-3 border-bottom">➡️ {annonce.quantity} {annonce.type}(s) </p>
                      { annonce.free_booking === 1 ? <p className="lead-3 border-bottom">✅ Reservation gratuite</p > : null}
                      { annonce.free_cancellation === 1 ? <p className="lead-3 border-bottom">✅ Annulation gratuite</p > : null}
                      { annonce.practical_access === 1 ? <p className="lead-3 border-bottom">✅ Accès praticable</p> : null }
                      { annonce.with_disability === 1 ? <p className="lead-3 border-bottom">🧑‍🦽 Accessible pour les handicapés</p> : null }
                      { annonce.near_tourist_area === 1 ? <p  className="lead-3 border-bottom">✈️ Proche d'un site touristique</p > : null }
                      { annonce.near_beach === 1 ? <p className="lead-3 border-bottom">🌊 Proche d'une plage</p> : null }
                      <p className="lead-3 border-bottom">🙎🏼 {annonce.max_num_person} personne(s) autorisée(s) par {annonce.type} </p>
                      { annonce.free_parking === 1 ? <p  className="lead-3 border-bottom">🚗 Parking gratuit</p > : null }
                      { annonce.free_wifi === 1 ? <p  className="lead-3 border-bottom">📶 WIFI gratuit</p > : null }
                      { annonce.free_breakfast === 1 ? <p  className="lead-3 border-bottom">🍵 Petit dejeuner offert</p > : null }
                      { annonce.with_animals === 1 ? <p  className="lead-3 border-bottom">🐶 Animaux autorisés</p > : null }
                      { annonce.can_smoke === 1 ? <p  className="lead-3 border-bottom">🚬 Autorisation de fumer</p > : null }
                      { annonce.have_party === 1 ? <p className="lead-3 border-bottom">🥳 Fete autorisée</p> : null }
                      { annonce.total_area > 0 ? <p className="lead-3 border-bottom">➡️ Espace total : {annonce.total_area}</p> : null}
                      { annonce.advance_in_months ? <p className="lead-3 border-bottom">➡️ {annonce.advance_in_months} mois d'avance </p> : null } 
                      { annonce.deposit_in_months ? <p className="lead-3 border-bottom">➡️ {annonce.deposit_in_months} mois de caution</p> : null } 
                    </div>
                  </div>
                </div>
                <Badge />
                <div className="row my-4">
                  <div className="col-md-8">
                    <div className="row row-cols-2 row-cols-lg-4 g-1 text-center">
                      { prices.hours ? <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm">
                          <div className="card-header py-3 text-bg-warning border-warning">
                            <h6 className="my-0 lead-5 fw-bold">Sieste</h6>
                          </div>
                          <div className="card-body">
                            <p className="card-title pricing-card-title fw-bold">💰 { prices.hours*2 } XAF<small className="text-body-secondary fw-light">/2 heures</small></p>
                          </div>
                        </div>
                      </div> : null }
                      { prices.days ? <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm">
                          <div className="card-header py-3 text-bg-warning border-warning">
                            <h6 className="my-0 lead-5 fw-bold">Nuitée</h6>
                          </div>
                          <div className="card-body">
                            <p className="card-title pricing-card-title fw-bold">💰 { prices.days } XAF<small className="text-body-secondary fw-light">/24 heures</small></p>
                          </div>
                        </div>
                      </div> : null }
                      { prices.weeks ? <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm">
                          <div className="card-header py-3 text-bg-warning border-warning">
                            <h6 className="my-0 lead-5 fw-bold">Semaine</h6>
                          </div>
                          <div className="card-body">
                            <p className="card-title pricing-card-title fw-bold">💰 { prices.weeks } XAF<small className="text-body-secondary fw-light">/7 jours</small></p>
                          </div>
                        </div>
                      </div> : null }
                      { prices.months ? <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm">
                          <div className="card-header py-3 text-bg-warning border-warning">
                            <h6 className="my-0 lead-5 fw-bold">Mois</h6>
                          </div>
                          <div className="card-body">
                            <p className="card-title pricing-card-title fw-bold">💰 { prices.months } XAF<small className="text-body-secondary fw-light">/1 mois</small></p>
                          </div>
                        </div>
                      </div> : null }
                      { annonce.deposit_amount > 0 ? <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm">
                          <div className="card-header py-3 px-0 text-bg-warning border-warning">
                            <h6 className="my-0 lead-5 fw-bold">Caution remboursable</h6>
                          </div>
                          <div className="card-body">
                            <p className="card-title pricing-card-title fw-bold">💰 { annonce.deposit_amount } XAF<small className="text-body-secondary fw-light">/Séjour</small></p>
                          </div>
                        </div>
                      </div> : null }
                    </div>
                    <hr />
                    <div className="row row-cols-2 pt-2 gy-1">
                      { annonce.n_living_rooms > 0 ? <p className="lead-3">➡️ {annonce.n_living_rooms} salon(s) par {annonce.type} </p> : null}
                      { annonce.n_bedrooms > 0 ? <p className="lead-3">➡️ {annonce.n_bedrooms} chambre(s) par {annonce.type}</p> : null}
                      { annonce.n_kitchens > 0 ? <p className="lead-3">➡️ {annonce.n_kitchens} cuisine(s) par {annonce.type}</p> : null}
                      { annonce.n_showers > 0 ? <p className="lead-3">➡️ {annonce.n_showers} douche(s) par {annonce.type}</p> : null}
                    </div>
                    <hr />
                    <p className="lead-3 mb-4 my-2">{annonce.description}</p>
                  </div>
                  <div className="col-md-4">
                    <div className="row row-cols-2 g-1 text-center">
                      { annonce.check_in && <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm">
                          <div className="card-header py-3 text-bg-warning border-warning">
                            <h6 className="my-0 lead-5 fw-bold">Heure arrivée</h6>
                          </div>
                          <div className="card-body">
                            <p className="card-title pricing-card-title fw-bold">🕐 {annonce.check_in}</p>
                          </div>
                        </div>
                      </div> }
                      { annonce.check_in && <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm">
                          <div className="card-header py-3 text-bg-warning border-warning">
                            <h6 className="my-0 lead-5 fw-bold">Heure départ</h6>
                          </div>
                          <div className="card-body">
                            <p className="card-title pricing-card-title fw-bold">🕐 {annonce.check_out}</p>
                          </div>
                        </div>
                      </div> }
                    </div> 
                    <div className="modal fade" id="check" tabIndex="-1" aria-labelledby="label_check">
                      <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title fw-bold" id="label_check">Vérifiez la disponibilité pour reserver !</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div className="modal-body">
                            <form method="GET" id="checkout" onSubmit={(e) => handleCheck(e, param_id, start_date, end_date, number_persons)} className="my__form p-2">
                              { annonce.category === "non meublés" && 
                                (<div className="card-header bg-warning fw-bold p-2 rounded mb-1">
                                    Prenez rendez-vous pour une visite ! 
                                </div> )
                              }
                              { availability === 1 && 
                                (<div className="alert alert-info my-2" role="alert">
                                    { check.n_pieces ? <span>{ check.n_pieces } pièce(s) disponible(s) !</span> : null } Cliquez sur "reserver" pour finaliser votre action. 
                                </div> )
                              }
                              { status === 0 && 
                                  (<div className="alert alert-danger my-2" role="alert">
                                      Vérifiez à nouveau vos informations. 
                                  </div>)
                              }
                              <div className="col-12 my-1">
                                <label htmlFor="start_date" className="form-label">Date d'arrivée</label>
                                <div className="input-group">
                                  <div className="input-group-text">Du</div>
                                  <input type="datetime-local" className="form-control" id="start_date" max={end_date} name="start_date" onChange={(e) => setStartDate(e.target.value)} required/>
                                </div>
                              </div>
                              <div className="col-12 my-1">
                                <label htmlFor="end_date" className="form-label">Date de départ</label>
                                <div className="input-group">
                                  <div className="input-group-text">Au</div>
                                  <input type="datetime-local" className="form-control" id="end_date" min={start_date} name="end_date" onChange={(e) => setEndDate(e.target.value) } required/>
                                </div>
                              </div>
                              <div className="col-12 my-1">
                                <label htmlFor="number_persons" className="form-label">Nombre de personnes maximum par piece</label>
                                <div className="input-group">
                                  <input type="number" className="form-control rounded-3" placeholder="Nombre de personnes maximum par piece" id="number_persons" name="number_persons" max={annonce.number_persons}  onChange={(e) => setNumberPersons(e.target.value) } required/>
                                </div>
                              </div>
                              <div className="col-12 my-2">
                              { load === 0 ? 
                                  annonce.visibility === 1 ? 
                                    <button type="submit" className="btn w-100 btn-warning rounded-3 fw-bold me-2"><i className="bi bi-search"></i> Vérifier la disponibilité</button> 
                                    :  
                                    <button type="submit" className="btn w-100 btn-warning fw-bold me-2 rounded-3 disabled"> Indisponible !</button>
                                  
                                  :
                                      <button className="btn btn-outline-warning fw-bold w-100 my-3 rounded-3" type="button" disabled>
                                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> En cours...
                                      </button>
                              }
                              </div>
                            </form>
                            </div>
                            <div className="modal-footer">
                              { availability === 1 &&
                                <div className="card-footer mb-2 my__card rounded w-100">
                                    <Link className="btn btn-warning fw-bold w-100 rounded" onClick={handleCheckOut}><i className="bi bi-bookmark-check-fill"></i> Reservez {duration} pour {total_price} XAF par pièce.</Link>
                                </div> 
                              } 

                              { availability === 0 &&
                                <div className="card-footer mb-2 my__card rounded w-100">
                                    <button type="button" className="btn btn-outline-warning rounded w-100 fw-bold" data-bs-toggle="modal" data-bs-target="#help">
                                        <i className="bi bi-bookmark-fill"></i> Indisponible !
                                    </button>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      { annonce.free_booking === 0 &&
                        <div className="card mb-4 rounded-3 shadow-sm">
                          <div className="card-header py-3 text-bg-warning border-warning">
                            <h6 className="my-0 lead-5 fw-bold">Conditions de reservation</h6>
                          </div>
                          <div className="card-body">
                            { annonce.booking_perc === 0 || annonce.booking_perc === "0" || annonce.booking_perc === null || annonce.booking_perc === undefined ?
                              <p className="card-title pricing-card-title">Pour reserver, vous devez payer 30 % du montant total de votre reservation !</p>
                            :
                              <p className="card-title pricing-card-title">Pour reserver, vous devez payer {annonce.booking_perc} % du montant total de votre reservation !</p>
                            }
                          </div>
                        </div>  
                      }
                      { annonce.free_cancellation === 0 &&
                        <div className="card mb-4 rounded-3 shadow-sm">
                          <div className="card-header py-3 text-bg-warning border-warning">
                            <h6 className="my-0 lead-5 fw-bold">Politiques d'annulation</h6>
                          </div>
                          <div className="card-body">
                          { annonce.perc_4cancellation === 0 || annonce.perc_4cancellation === "0" || annonce.perc_4cancellation === null || annonce.perc_4cancellation === undefined ?
                            <p className="card-title pricing-card-title">Non remboursable en cas d'annulation !</p>
                          :
                           <p className="card-title pricing-card-title">En cas d'annulation moins de {annonce.free_cancellation_time} jours avant votre arrivée, vous recevrez {annonce.perc_4cancellation} % du montant versé pour votre reservation !</p>
                          }
                          </div>
                        </div>  
                      }
                      <div className="card my-3">
                          <div className="card-header bg-warning fw-bold">
                              <h6 className="fw-bold"><i className="bi bi-search"></i> Recherche rapide</h6>
                          </div>
                          <ul className="list-group list-group-flush">
                              <li className="list-group-item"><i className="bi bi-arrow-right-circle text-warning"></i> <StyledLink $theme={theme} to={`/results/q=?text=${annonce.designation}`}> Voir + sur {annonce.designation}</StyledLink> </li>
                              <li className="list-group-item"><i className="bi bi-arrow-right-circle text-warning"></i> <StyledLink $theme={theme} to={`/results/q=?town=${annonce.town}&type=${annonce.type}&category=${annonce.category}`}>Voir + de {annonce.type} - {annonce.category} à {annonce.town}</StyledLink> </li>
                              <li className="list-group-item"><i className="bi bi-arrow-right-circle text-warning"></i> <StyledLink $theme={theme} to={`/results/q=?town=${annonce.town}&category=${annonce.category}`}>Voir + de {annonce.category} à {annonce.town}</StyledLink></li>
                          </ul>
                      </div>  
                  </div>
                </div>
              </div>

              <div className="modal fade" id="help" tabIndex="-1" aria-labelledby="contactModal">
                  <div className="modal-dialog">
                      <div className="modal-content">
                          <div className="modal-header">
                              <h5 className="modal-title" id="contactModal">{annonce.type} - {annonce.category} | {annonce.designation}</h5>
                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div className="modal-body">
                              Cette annonce n'est pas disponible sur cette periode mais vous pouvez cliquer sur les liens ci-dessous pour voir nos recommandations ou contactez notre service client !
                          </div>
                          <div className="modal-footer">
                              <Link to={ `/results/q=?town=${annonce.town}&type=${annonce.type}&category=${annonce.category}` } className="btn btn-outline-warning fw-bold w-100">Recommandations</Link>
                              <Link to={ `/about#contact` } className="btn btn-warning fw-bold w-100">Service client</Link>
                          </div>
                      </div>
                  </div>
              </div>

              <CallToAction />
              <Filter countAds={countAds} />
              <Explore />
            </Fragment>
          )}
          </MainContainer>

          {/* Footer Section */}
          <Footer />
          <CheckButton />
          <FilterButton />
          <ScrollButton />
          <MobileBottomHeader/>
      </Fragment>
      
    )
}

export default Property