import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFetch22, useTheme } from "../../../hooks";
import Error from "../../../composants/public/widgets/error";
import Admin from "../../../composants/dashboard/header";
import { AdminSection, HeroAdminSection, HeroTitleAdmin, MainContainer, StyledTitle2 } from "../../../styles/atoms";
import Column from "../../../composants/dashboard/column";
import Loading from "../../../composants/public/widgets/loading";
import Stats from "../../../composants/dashboard/stats";
import Footer from "../../../composants/public/sections/footer";
import ScrollButton from "../../../composants/public/widgets/scrollUp";
import MobileBottomHeader from "../../../composants/public/sections/mobileBottomHeader";
import ViewsMeubles from "../../../composants/dashboard/viewsMeubles";

function ShowMeubles() {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const { theme } = useTheme();

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user") || '{}');
        const storedToken = JSON.parse(localStorage.getItem("token") || '{}');
        setUser(storedUser);
        setToken(storedToken);
    }, []);

    const { isLoading22, data21, data22, error22 } = useFetch22(
        `https://api.book-easily.com/api/admin/categories/2/ads`,
        `https://api.book-easily.com/api/${user?.role_id === 3 ? 'admin' : 'user'}/count_items/bookings`,
        token
    );

    const meubles = data21;
    const countBookings = data22?.data?.bookings + data22?.data?.cancelled;

    if (error22) {
        return <Error />;
    }

    if (!user) {
        return <Error />;
    }

    return (
        <Fragment>
            {/* Header Section */}
            <Admin />

            <MainContainer theme={theme}>
                <HeroAdminSection theme={theme}>
                    <HeroTitleAdmin theme={theme}>Bienvenue {user.first_name} !</HeroTitleAdmin>
                    <div className="row">
                        <StyledTitle2 theme={theme}>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">
                                            <i className="bi bi-house-check-fill text-warning"></i>
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item text-warning" aria-current="page">
                                        Meublés
                                    </li>
                                </ol>
                            </nav>
                        </StyledTitle2>
                    </div>
                </HeroAdminSection>

                <AdminSection>
                    <div className="col-md-4 col-lg-3">
                        <Column theme={theme} />
                    </div>

                    <div className="col-md-8 col-lg-9">
                        {isLoading22 ? (
                            <Loading />
                        ) : (
                            user.role_id === 3 && (
                                <Fragment>
                                    <Stats countBookings={countBookings} />
                                    <ViewsMeubles group="Meublés" datas={meubles} />
                                </Fragment>
                            )
                        )}
                    </div>
                </AdminSection>
            </MainContainer>

            <Footer />
            <ScrollButton />
            <MobileBottomHeader />
        </Fragment>
    );
}

export default ShowMeubles;
