import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ImgCardUpsell, SpanPriceUpsell } from "../../../styles/atoms";
import React from 'react';

const CardUpsell = React.memo(({ id, town, title, price, cover, description }) => {

  const settings = {
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    customPaging: (i) => (
      <div className="row">
        <Link className="col" to="#" aria-label={`Miniature de la diapositive ${i + 1}`}>
          <img 
            src={`${cover[i]}`} 
            className="rounded-1" 
            loading="lazy" 
            alt={`Diapositive ${i + 1}`}  // Remplacé pour éviter les mots comme "image"
            width="20" 
            height="20" 
          />
        </Link>
      </div>
    ),
  };

  const handleImageError = (e) => {
    e.target.src = "https://api.book-easily.com/storage/assets/NoImage.jpg"; // Mettre une image par défaut
  };

  return (
    <div className="col rounded card my__card m-1" key={`${id}-${title}`}>
      <Slider {...settings}>
        {cover.map((image, index) => (
          <ImgCardUpsell
            srcSet={`${image} 1x, ${image.replace(/(\.\w+)$/, '@2x$1')} 2x`}  // Optimisation pour écrans à haute résolution
            src={image}
            loading="lazy"
            key={index}
            className="img-fluid card-img"
            alt={description || title}  // Utilisation de description pour plus de clarté
            onError={handleImageError}
          />
        ))}
      </Slider>

      <SpanPriceUpsell className="fw-bold">
        {price} XAF
      </SpanPriceUpsell>

      <div className="card-body mt-4">
        <h4 className="fw-bold">
          {town} - {title}
        </h4>
        <p>{description}</p>
      </div>

      <div className="card-footer row g-2">
        <div className="col-12 col-md-6">
          <Link 
            to="https://wa.me/237695931631" 
            className="btn btn-success px-1 fw-bold w-100 rounded-5" 
            target="_blank" 
            aria-label="Contacter via WhatsApp"
            rel="noopener noreferrer"
          >
            <i className="bi bi-whatsapp"></i> (+237) 695931631
          </Link>
        </div>

        <div className="col-12 col-md-6">
          <Link 
            to="mailto:contact@book-easily.com?subject=Je%20suis%20intéressé(e)%20par%20un%20Bon%20Plan%20!" 
            className="btn btn-outline-success fw-bold w-100 rounded-5" 
            target="_blank" 
            aria-label="Envoyer un email"
            rel="noopener noreferrer"
          >
            <i className="bi bi-envelope"></i> Mail
          </Link>
        </div>
      </div>
    </div>
  );
});

export default CardUpsell;
