import { Fragment, useEffect, useState } from "react";
import { useTheme } from "../hooks";
import Header from "../composants/public/sections/header";
import { EmailVerifySection, MainContainer } from "../styles/atoms";
import Footer from "../composants/public/sections/footer";
import MobileBottomHeader from "../composants/public/sections/mobileBottomHeader";

function EmailVerify() {
    const { theme } = useTheme();
    document.title = 'BookEasily | Confirmez votre email';
    
    const [status, setStatus] = useState(0);
    const [loading, setLoading] = useState(false);

    // Retrieve user info from localStorage securely
    const user = JSON.parse(localStorage.getItem("user")) || null;
    const token = JSON.parse(localStorage.getItem("token")) || null;
    const xsrfToken = localStorage.getItem("cookies");

    const [role, setRole] = useState('');

    useEffect(() => {
        if (user && user.role_id) {
            setRole(user.role_id);
        }
    }, [user]);

    useEffect(() => {
        // Safely set meta description
        const desc = document.querySelector('meta[name="description"]');
        if (desc) {
            desc.setAttribute('content', 'BookEasily | Devenez partenaire et boostez votre visibilité et vos réservations');
        }
    }, []);

    const handleEmailVerify = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await fetch('https://api.book-easily.com/api/email/verification-notification', {
                method: 'POST',
                mode: "cors",
                cache: "no-cache",
                credentials: "include",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': xsrfToken || '',
                    'Accept': 'application/json',
                },
            });

            const data = await response.json();
            setStatus(data?.status || 0);
            
            if (data?.status === 0) {
                window.location.assign("/dashboard");
            }
        } catch (error) {
            console.error('Email verification error:', error);
            setStatus(0);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <Header />
            <MainContainer theme={theme}>
                <EmailVerifySection>
                    <div className="py-4 text-center text-white">
                        <h2 className="display-5 fw-bold">Confirmez votre mail !</h2>
                        <div className="col-lg-6 mx-auto">
                            <p className="lead mb-4">
                                Hello {user?.first_name} 🙂, Bienvenue dans la communauté <span className="text-warning">BookEasily</span>, nous sommes très heureux de vous compter parmi { role === 3 ? <span>nos partenaires</span> : <span>nous</span> } !
                            </p>
                            <p className="lead mb-4 fw-bold">Cliquez sur le lien que vous avez reçu dans votre boite mail pour valider votre inscription.</p>
                            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                                {loading ? (
                                    <button className="btn btn-outline-warning fw-bold w-100 my-3 rounded-5" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> En cours...
                                    </button>
                                ) : (
                                    <button type="button" onClick={handleEmailVerify} className="btn btn-warning btn-md fw-bold px-4 rounded-5">
                                        {status === 1 ? <span>Vous avez reçu un nouveau mail de confirmation</span> : <span>Renvoyez le mail de confirmation</span>}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </EmailVerifySection>
            </MainContainer>
            <Footer />
            <MobileBottomHeader />
        </Fragment>
    );
}

export default EmailVerify;
