import React, { useState, useEffect } from "react";
import { useTheme } from "../../hooks";
import { DeleteButton, PaginateLink, StyledLink, StyledTitle2, TD } from '../../styles/atoms';

const AvailableAds = ({ group, datas }) => {
    const { theme } = useTheme();
    const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null;
    const x_xsrf_token = localStorage.getItem("cookies");

    const [books, setBooks] = useState(datas?.data || []);
    const [metaLinks, setMetaLinks] = useState(datas?.meta?.links || []);
    const [cancellationReason, setCancellationReason] = useState('');
    const [paramId, setParamId] = useState('');

    const handleDeleteBooking = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("cancellation_reason", cancellationReason);

        try {
            const response = await fetch(`https://api.book-easily.com/api/cancel/booking/${paramId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json; charset=UTF-8',
                    'X-XSRF-TOKEN': x_xsrf_token
                },
                body: formData,
                credentials: 'include'
            });

            const data = await response.json();
            if (data?.status === 1) {
                setBooks(books.filter(book => book.id !== paramId));
                setParamId(''); // Clear paramId after deletion
                window.location.reload();
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handlePaginate = async (e, url) => {
        e.preventDefault();

        try {
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'X-XSRF-TOKEN': x_xsrf_token
                },
                credentials: 'include'
            });

            const data = await response.json();
            if (data?.status === 1) {
                setBooks(data?.data || []);
                setMetaLinks(data?.meta?.links || []);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString();
    };

    useEffect(() => {
        setBooks(datas?.data || []);
        setMetaLinks(datas?.meta?.links || []);
    }, [datas]);

    return (
        <>
            <div className="table-responsive my__card card mt-3">
                <table className="table table-sm">
                    <caption className="m-3">
                        <StyledTitle2 theme={theme}>{group}</StyledTitle2>
                    </caption>
                    <thead className="bg-warning text-capitalize">
                        <tr>
                            <th scope="col" className="p-2">Statut</th>
                            <th scope="col" className="p-2">Annonce</th>
                            <th scope="col" className="p-2">Modifier</th>
                            <th scope="col" className="p-2">Annuler</th>
                            <th scope="col" className="p-2">Fait le</th>
                            <th scope="col" className="p-2">Début</th>
                            <th scope="col" className="p-2">Fin</th>
                            <th scope="col" className="p-2">Nombre de pièces</th>
                        </tr>
                    </thead>
                    <tbody>
                        {books.map(({ id, canceled, n_pieces, ad_id, entering_at, living_at, transaction_date, ref, out_book }) => out_book === 1 && (
                            <tr key={ref}>
                                <TD theme={theme}>
                                    {canceled === 1 ? <span>Disponible</span> : <span>Indisponible</span>}
                                </TD>
                                <TD theme={theme}>
                                    <StyledLink target="_blank" $theme={theme} to={`/check/property/${ad_id}`}>
                                        <i className="bi bi-eye-fill"></i>
                                    </StyledLink>
                                </TD>
                                <TD theme={theme}>
                                    <StyledLink $theme={theme} to={`/dashboard/availability/update/${id}`}>
                                        <i className="bi bi-pencil-fill"></i>
                                    </StyledLink>
                                </TD>
                                <TD theme={theme}>
                                    {canceled === 1 ? (
                                        <DeleteButton className="disabled">
                                            <i className="bi bi-x-circle-fill"></i>
                                        </DeleteButton>
                                    ) : (
                                        <DeleteButton
                                            onClick={() => setParamId(id)}
                                            data-bs-toggle="modal"
                                            data-bs-target="#deleteBookedBooking"
                                        >
                                            <i className="bi bi-x-circle-fill"></i>
                                        </DeleteButton>
                                    )}
                                </TD>
                                <TD theme={theme}>{formatDate(transaction_date)}</TD>
                                <TD theme={theme}>{formatDate(entering_at)}</TD>
                                <TD theme={theme}>{formatDate(living_at)}</TD>
                                <TD theme={theme}>{n_pieces}</TD>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <nav aria-label="Page navigation" className="mt-3">
                <ul className="pagination justify-content-center">
                    {metaLinks.map(({ url, label, active }) => (
                        <li className={`page-item ${active ? 'active' : ''}`} key={label}>
                            {url ? (
                                <PaginateLink
                                    className="page-link"
                                    style={{ backgroundColor: active ? '#DF9600' : 'transparent', color: active ? 'white' : 'inherit' }}
                                    onClick={(e) => handlePaginate(e, url)}
                                >
                                    {label}
                                </PaginateLink>
                            ) : null}
                        </li>
                    ))}
                </ul>
            </nav>
            <div className="modal fade" id="deleteBookedBooking" tabIndex="-1" aria-labelledby="deleteBookedBookingModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="deleteBookedBookingModal">
                                Annulation de l'indisponibilité de l'annonce N°{paramId}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleDeleteBooking}>
                                <input
                                    type="text"
                                    name="cancellation_reason"
                                    className="form-control my-2"
                                    placeholder="Raison de votre annulation"
                                    id="cancellation_reason"
                                    onChange={(e) => setCancellationReason(e.target.value)}
                                    required
                                />
                                <DeleteButton type="submit">
                                    <i className="bi bi-x-circle-fill"></i> Annuler
                                </DeleteButton>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AvailableAds;
