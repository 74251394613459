import React from 'react';
import { HomeLogo, LoaderWrapper } from '../../../styles/atoms';
import LoaderIllustration from '../../../assets/logo.png';

const Loading = () => (
    <LoaderWrapper>
        <HomeLogo
            src={LoaderIllustration}
            alt="Logo de chargement"
            loading="lazy" // Optimisation du chargement des images
        />
        <div className="spinner-grow text-warning mt-2" role="status">
            <span className="visually-hidden">Chargement...</span>
        </div>
    </LoaderWrapper>
);

export default Loading;
