import React, { useState, useEffect } from 'react';
import { Button } from './styles/stylesScrollUp';

const ScrollButton = () => {
    const [visible, setVisible] = useState(false);

    // Fonction pour déterminer si le bouton doit être visible
    const toggleVisible = () => {
        const scrolled = window.scrollY;
        setVisible(scrolled > 300);
    };

    // Fonction pour faire défiler la page vers le haut
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    // Utiliser useEffect pour ajouter et nettoyer l'écouteur d'événements de défilement
    useEffect(() => {
        window.addEventListener('scroll', toggleVisible);
        // Nettoyage de l'écouteur d'événements lors du démontage du composant
        return () => window.removeEventListener('scroll', toggleVisible);
    }, []);

    return (
        <Button onClick={scrollToTop} style={{ display: visible ? 'block' : 'none' }}>
            <i className="bi bi-arrow-up-circle-fill" />
        </Button>
    );
};

export default ScrollButton;
