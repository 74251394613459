import React from 'react';
import I1 from "../../../assets/2.jpg";
import I2 from "../../../assets/12.jpg";
import I6 from "../../../assets/50.jpg";
import { useTheme } from "../../../hooks";
import { CatLink, SponsorSection, StyledTitle, TitlePlusSection } from "../../../styles/atoms";

function Explore () {
    const { theme } = useTheme();

    const images = [
        {
            id: '1',
            title: "Proche d'un site touristique",
            img: I1,
            link: `/results/q=?near_tourist_area=1`
        },
        {
            id: '2',
            title: "Proche d'une plage",
            img: I2,
            link: `/results/q=?near_beach=1`
        },
        {
            id: '3',
            title: "Fêtes autorisées",
            img: I6,
            link: `/results/q=?have_party=1`
        }
    ];

    return (
        <SponsorSection theme={theme}>
            <TitlePlusSection>
                <StyledTitle theme={theme}>Découverte <i className="bi bi-stars text-warning"></i></StyledTitle>
            </TitlePlusSection>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 align-items-stretch gy-3 pb-2">
                {images.map(({ id, title, img, link }) => (
                    <CatLink to={link} className="col" key={id}>
                        <div className="card h-100 text-bg-dark" 
                             style={{ 
                                 backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${img})`,
                                 backgroundPosition: 'center center',
                                 backgroundSize: 'cover' 
                             }}>
                            <div className="d-flex flex-column h-100 px-4 py-5 pb-3 text-shadow-1">
                                <h4 className="py-3 mt-5 mb-3 fw-bold">{title}</h4>
                                <ul className="d-flex list-unstyled mt-auto">
                                    <li className="me-auto">
                                        Cliquez pour voir les annonces <i className="bi bi-arrow-right-circle"></i>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </CatLink>
                ))}
            </div>
        </SponsorSection>
    );
};

export default Explore;
