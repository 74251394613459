import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../../hooks";
import { DeleteButton, PaginateLink, StyledLink, StyledTitle2, TD } from "../../styles/atoms";

// Déclaration des constantes API
const API_BASE_URL = 'https://api.book-easily.com/api';

function ViewsLocations({ group, datas }) {
    const { theme } = useTheme();
    const [ads, setAds] = useState(datas?.data || []);
    const [metaLinks, setMetaLinks] = useState(datas?.meta?.links || []);
    const [enteringAt, setEnteringAt] = useState('');
    const [livingAt, setLivingAt] = useState('');
    const [nPieces, setNPieces] = useState(1);
    const [totalPieces, setTotalPieces] = useState(1);
    const [load, setLoad] = useState(false);
    const [status, setStatus] = useState(0);
    const [paramId, setParamId] = useState(0);
    const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null;
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
    const xsrfToken = localStorage.getItem("cookies");

    // Fonction pour la gestion des appels API
    const apiRequest = async (url, options) => {
        try {
            const response = await fetch(url, options);
            const data = await response.json();
            if (data.status === 1) return data;
            throw new Error(data.message || 'Une erreur est survenue');
        } catch (err) {
            console.error(err.message);
            throw err;
        }
    };

    const handleBookedBooking = async (e) => {
        e.preventDefault();
        setLoad(true);
        const forms = new FormData();
        forms.append("entering_at", enteringAt);
        forms.append("living_at", livingAt);
        forms.append("n_pieces", nPieces);
        forms.append("ad_id", paramId);
        forms.append("for_me", 1);
        forms.append("first_name", user.first_name);
        forms.append("last_name", user.last_name);
        forms.append("booked", 1);

        try {
            const data = await apiRequest(`${API_BASE_URL}/booking`, {
                method: "POST",
                body: forms,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json; charset=UTF-8',
                    'X-XSRF-TOKEN': xsrfToken
                },
                credentials: "include",
                mode: "cors",
                cache: "default"
            });
            setStatus(data.status);
            if (data.status === 1) window.location.reload();
        } finally {
            setLoad(false);
        }
    };

    const handleDelete = async (e, id) => {
        e.preventDefault();
        try {
            const data = await apiRequest(`${API_BASE_URL}/delete/ads/${id}`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'X-XSRF-TOKEN': xsrfToken
                },
                credentials: "include",
                mode: "cors",
                cache: "default"
            });
            if (data.status === 1) window.location.reload();
        } catch (err) {
            console.error(err.message);
        }
    };

    const handleVisibility = async (e, id, val) => {
        e.preventDefault();
        const formVisible = new FormData();
        formVisible.append("visibility", val ? 1 : 0);

        try {
            const data = await apiRequest(`${API_BASE_URL}/ads/${id}`, {
                method: "POST",
                body: formVisible,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'X-XSRF-TOKEN': xsrfToken
                },
                credentials: "include",
                mode: "cors",
                cache: "default"
            });
            if (data.status === 1) window.location.reload();
        } catch (err) {
            console.error(err.message);
        }
    };

    const handlePaginate = async (e, url) => {
        e.preventDefault();
        try {
            const data = await apiRequest(url, {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'X-XSRF-TOKEN': xsrfToken
                },
                credentials: "include",
                mode: "cors",
                cache: "default"
            });
            setAds(data.data);
            setMetaLinks(data.meta.links);
        } catch (err) {
            console.error(err.message);
        }
    };

    // Nettoyage des effets secondaires pour éviter les fuites de mémoire
    useEffect(() => {
        return () => {
            // nettoyage des ressources si nécessaire
        };
    }, []);

    return (
        <Fragment>
            <div className="table-responsive my__card card">
                <table className="table table-sm">
                    <caption className="m-3"><StyledTitle2 theme={theme}>{group}</StyledTitle2></caption>
                    <thead className="bg-warning text-capitalize">
                        <tr>
                            <th scope="col" className="p-2">Partager</th>
                            <th scope="col" className="p-2">Disponibilité</th>
                            <th scope="col" className="p-2">Visible</th>
                            <th scope="col" className="p-2">Aperçu</th>
                            <th scope="col" className="p-2">Modifier</th>
                            <th scope="col" className="p-2">Supprimer</th>
                            <th scope="col" className="p-2">Noms</th>
                            <th scope="col" className="p-2">Courte description</th>
                            <th scope="col" className="p-2">Types</th>
                            <th scope="col" className="p-2">Quantité</th>
                            <th scope="col" className="p-2">Villes</th>
                            <th scope="col" className="p-2">Quartiers</th>
                            <th scope="col" className="p-2">Prix/Mois</th>
                            <th scope="col" className="p-2">Vues</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ads.length ? ads.map(({ id, name, category, quantity, prices, designation, type, town, street, view, visibility, ref }) => (
                            <tr key={ref}>
                                <TD theme={theme}>
                                    <div className="row g-1">
                                        <div className="col fb-share-button" data-href={`https://www.book-easily.com/${category}/property/${designation.replace(/\s|&|\/|_/g, '_')}/${id}`} data-layout="button" data-size="small">
                                            <Link target="_blank" to={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.book-easily.com/${category}/property/${designation.replace(/\s|&|\/|_/g, '_')}/${id}&src=sdkpreparse`} className="fb-xfbml-parse-ignore">
                                                <i className="bi bi-facebook"></i>
                                            </Link>
                                        </div>
                                        <div className="col">
                                            <Link target="_blank" to={`https://api.whatsapp.com/send?text=${type}%20-%20${category}%20de%20${designation}%20à%20${town}%20-%20${street}%20disponible%20sur%20BookEasily.%20Cliquez%20sur%20le%20lien%20suivant%20pour%20consulter%20et%20effectuer%20votre%20reservation%20:%20https://www.book-easily.com/${category}/property/${designation.replace(/\s|&|\/|_/g, '_')}/${id}`}>
                                                <i className="bi bi-whatsapp text-success"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </TD>
                                <TD theme={theme}>
                                    <i onClick={() => { setParamId(id); setTotalPieces(quantity); }} data-bs-toggle="modal" data-bs-target="#bookedBooking" className="bi bi-calendar-check"></i>
                                </TD>
                                <TD theme={theme}>
                                    <input className="form-check-input" type="checkbox" checked={visibility} onChange={(e) => handleVisibility(e, id, e.target.checked)} id={`visible-${id}`} name='visible' />
                                </TD>
                                <TD theme={theme}>
                                    <StyledLink target="_blank" $theme={theme} to={`/${category}/property/${name}/${id}`}>
                                        <i className="bi bi-eye-fill"></i>
                                    </StyledLink>
                                </TD>
                                <TD theme={theme}>
                                    <StyledLink $theme={theme} to={`/dashboard/categories/update_ads/${id}`}>
                                        <i className="bi bi-pencil-fill"></i>
                                    </StyledLink>
                                </TD>
                                <TD theme={theme}>
                                    <form onSubmit={(e) => handleDelete(e, id)}>
                                        <DeleteButton type="submit"><i className="bi bi-x-circle-fill"></i></DeleteButton>
                                    </form>
                                </TD>
                                <TD theme={theme}>{designation}</TD>
                                <TD theme={theme}>{name}</TD>
                                <TD theme={theme}>{type}</TD>
                                <TD theme={theme}>{quantity}</TD>
                                <TD theme={theme}>{town}</TD>
                                <TD theme={theme}>{street}</TD>
                                <TD theme={theme}>{prices.months} XAF</TD>
                                <TD theme={theme}>{view}</TD>
                            </tr>
                        )) : (
                            <tr>
                                <td colSpan="14" className="text-center">Aucune donnée disponible</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <nav aria-label="..." className='my-3'>
                <ul className="pagination justify-content-center">
                    {metaLinks.map(({ url, label, active }) => (
                        <li className={`page-item ${active ? 'active' : ''}`} key={label}>
                            {url && (
                                <PaginateLink className='page-link' onClick={(e) => handlePaginate(e, url)} style={active ? { backgroundColor: '#DF9600', color: 'white' } : {}}>
                                    {label}
                                </PaginateLink>
                            )}
                        </li>
                    ))}
                </ul>
            </nav>
            <div className="modal fade" id="bookedBooking" tabIndex="-1" aria-labelledby="bookedBookingModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="bookedBookingModal">Définissez la période d'indisponibilité de votre annonce</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleBookedBooking}>
                                <div className="mb-3">
                                    <label htmlFor="entering_at" className="form-label">Date de début *</label>
                                    <input type="datetime-local" className="form-control" id="entering_at" max={livingAt} value={enteringAt} onChange={(e) => setEnteringAt(e.target.value)} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="living_at" className="form-label">Date de fin *</label>
                                    <input type="datetime-local" className="form-control" id="living_at" min={enteringAt} value={livingAt} onChange={(e) => setLivingAt(e.target.value)} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="number_pieces" className="form-label">Nombre de pièces *</label>
                                    <input type="number" className="form-control" id="number_pieces" min="1" max={totalPieces} value={nPieces} onChange={(e) => setNPieces(Number(e.target.value))} placeholder="Nombre de pièces" required />
                                    <small className="text-danger d-block mt-1">Total disponible : {totalPieces}</small>
                                </div>
                                <div className="text-warning mb-3">
                                    * = champ obligatoire
                                </div>
                                <button className="w-100 btn btn-outline-warning fw-bold" type="submit" disabled={load}>
                                    {load ? (
                                        <>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> En cours...
                                        </>
                                    ) : (
                                        status === 1 ? <span>Opération réussie ✔</span> : <span>Rendre indisponible</span>
                                    )}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default ViewsLocations;
