import React, { useEffect, useState } from 'react';
import { Button } from "./styles/stylesCheckButtom";
import './styles/CheckButton.css'; // Assurez-vous que ce fichier CSS est importé

const CheckButton = () => {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        setVisible(window.scrollY > 1); // Afficher le bouton si l'utilisateur a défilé vers le bas
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible);
        return () => window.removeEventListener('scroll', toggleVisible);
    }, []);

    return (
        <Button
            className={`rounded-5 shadow ${visible ? 'animate' : ''}`}
            onClick={scrollToTop}
            style={{
                display: visible ? 'block' : 'none',
                backgroundColor: '#e80000',
                boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
            }}
            data-bs-toggle="modal"
            data-bs-target="#check"
        >
            Réservez 🕐
        </Button>
    );
};

export default CheckButton;
