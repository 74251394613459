import { Form, Link } from 'react-router-dom';
import { Fragment, useEffect, useState, useMemo } from 'react';
import img from '../../assets/user.png';
import { LogoProfil, LogoutButton, StyledLink } from '../../styles/atoms';

function Column({ theme }) {
  const [role, setRole] = useState('');
  const token = localStorage.getItem("token");
  const x_xsrf_token = localStorage.getItem("cookies");

  // Mémoriser l'utilisateur pour éviter les re-rendus inutiles
  const user = useMemo(() => JSON.parse(localStorage.getItem("user")) || {}, []);

  useEffect(() => {
    if (user.role_id === 2) setRole('Client');
    if (user.role_id === 3) setRole('Partenaire');
  }, [user.role_id]); // Dépendance sur user.role_id uniquement

  const logout = async (e) => {
    e.preventDefault();
    try {
      await fetch('https://api.book-easily.com/api/logout', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-XSRF-TOKEN': x_xsrf_token
        },
      });
      localStorage.clear();
      window.location.assign('/');
    } catch (err) {
      console.error('Logout error:', err.message);
    }
  };

  return (
    <div className="mt-3">
      <div className="card my__card">
        <div className="d-flex flex-row justify-content-center card-header bg-white">
          <Link to='/dashboard/user'>
            <LogoProfil src={user.photo_url || img} alt={user.last_name || 'User'} />
          </Link>
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item text-center">
            <i className="bi bi-person-lines-fill mx-1"></i><span> {user.last_name} </span>
            <StyledLink to={`/dashboard/user/update/${user.id}`}>
              <i className="bi bi-pencil-fill"></i>
            </StyledLink>
          </li>
          <li className="list-group-item text-center fw-bold">
            <i className="bi bi-briefcase-fill mx-1"></i><span> {role} </span>
          </li>
        </ul>
      </div>
      <div className="card my__card mt-3">
        <div className="card-header bg-warning fw-bold">
          Paramètres
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <i className="bi bi-person"></i> <StyledLink to='/dashboard/user'>Informations personnelles</StyledLink>
          </li>
          <li className="list-group-item">
            <i className="bi bi-key"></i> <StyledLink to={`/dashboard/user/update/password/${user.id}`}>Modifiez votre mot de passe</StyledLink>
          </li>
          <li className="list-group-item">
            <i className="bi bi-credit-card"></i> <StyledLink to='#'>Méthode de paiement</StyledLink>
          </li>
          <li className="list-group-item">
            <Form method='POST' onSubmit={logout}>
              <i className="bi bi-box-arrow-up-left"></i> <LogoutButton type='submit'>Déconnexion</LogoutButton>
            </Form>
          </li>
        </ul>
      </div>
      <div className="my__card card mt-3">
        <div className="card-header bg-warning fw-bold">
          Accès rapides
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <i className="bi bi-house-check"></i> <StyledLink to='/dashboard'>Tableau de bord</StyledLink>
          </li>
          {user.role_id === 3 && (
            <Fragment>
              <li className="list-group-item">
                <div className="btn-group">
                  <button className="btn p-0 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="true">
                    <i className="bi bi-bag-plus"></i> <StyledLink>Ajoutez une annonce</StyledLink>
                  </button>
                  <ul className="dropdown-menu">
                    <li className="dropdown-item"><StyledLink to='/dashboard/categories/hotels/new_ads'><i className="bi bi-arrow-right-circle"></i> Hotel</StyledLink></li>
                    <li className="dropdown-item"><StyledLink to='/dashboard/categories/meubles/new_ads'><i className="bi bi-arrow-right-circle"></i> Meublé</StyledLink></li>
                    <li className="dropdown-item"><StyledLink to='/dashboard/categories/non_meubles/new_ads'><i className="bi bi-arrow-right-circle"></i> Location/Non Meublé</StyledLink></li>
                  </ul>
                </div>
              </li>
              <li className="list-group-item">
                <button className="btn p-0 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="true">
                  <i className="bi bi-eye"></i> <StyledLink>Voir vos annonces</StyledLink>
                </button>
                <ul className="dropdown-menu">
                  <li className="dropdown-item"><StyledLink to='/dashboard/categories/hotels/views_ads'><i className="bi bi-arrow-right-circle"></i> Hotels</StyledLink></li>
                  <li className="dropdown-item"><StyledLink to='/dashboard/categories/meubles/views_ads'><i className="bi bi-arrow-right-circle"></i> Meublés</StyledLink></li>
                  <li className="dropdown-item"><StyledLink to='/dashboard/categories/non_meubles/views_ads'><i className="bi bi-arrow-right-circle"></i> Locations/Non Meublés</StyledLink></li>
                </ul>
              </li>
            </Fragment>
          )}
        </ul>
      </div>
    </div>
  );
}

export default Column;
