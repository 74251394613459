import React, { useEffect, useState } from 'react';
import { Button } from './styles/stylesFilterButton';

// Utilisation de useEffect pour la gestion des événements d'écoute de défilement
const FilterButton = () => {
  const [visible, setVisible] = useState(false);

  // Fonction pour vérifier la visibilité du bouton en fonction du défilement
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    setVisible(scrolled > 300);
  };

  useEffect(() => {
    // Ajout de l'écouteur d'événement de défilement
    window.addEventListener('scroll', toggleVisible);

    // Nettoyage de l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener('scroll', toggleVisible);
    };
  }, []);

  return (
    <Button href="#filter" style={{ display: visible ? 'inline' : 'none' }}>
      <i className="bi bi-search"></i>
    </Button>
  );
};

export default FilterButton;
