import React from "react";
import { useTheme } from "../../../hooks";
import { GridHelp, StyledTitle, StyledTitle3 } from "../../../styles/atoms";

// Utilisation de React.memo pour éviter les rendus inutiles si les props ne changent pas
function Faqs () {
    const { theme } = useTheme();

    const faqItems = [
        {
            question: "A quoi sert la commission que nous vous donnons ?",
            answer: "Pour lancer les campagnes marketing pour vous mettre en avant en ligne, proposer des rabais (à nos frais) au client pour les encourager à effectuer une réservation, Assistante disponible 7j/7."
        },
        {
            question: "Dois je confirme une réservation ?",
            answer: "Malheureusement non, vous n'avez pas la possibilité de confirmer une réservation."
        },
        {
            question: "Pourrais annuler la réservation d'un client ?",
            answer: "Oui, et vous devez par la suite procéder immédiatement au remboursement total y compris les frais de transaction bancaire si vous avez déjà reçu le virement."
        },
        {
            question: "Que se passe-t-il si le client ne vient pas ?",
            answer: "Si la réservation de votre logement est gratuite alors vous ne payez aucune commission. Par contre si elle est payante, vous allez nous verser la commission sur le montant que le client a payé pour effectuer la réservation."
        },
        {
            question: "Pour une nuitée, pourrais-je choisir mon heure d'arrivée et mon heure de départ ?",
            answer: "Malheureusement non, vous ne pouvez choisir que la date de départ, la date d'arrivée et préciser le nombre de personnes et de logement que vous voulez."
        },
        {
            question: "Comment réserver pour une nuitée ?",
            answer: "Pour pouvoir réserver il faut choisir le logement qui vous intéresse et vérifier la disponibilité en entrant l'heure le jour d'arrivée, le jour de départ, le nombre de personnes que vous êtes et le nombre de logements."
        },
        {
            question: "Comment être sûr que ma réservation a abouti ?",
            answer: "Lorsque vous finalisez votre réservation, nous vous envoyons un message de confirmation par mail que vous pouvez également présenter à l'accueil de l'hébergement."
        }
    ];

    return (
        <div>
            <StyledTitle theme={theme}>Questions courantes (FAQs)</StyledTitle>
            <GridHelp>
                {faqItems.map(({ question, answer }, index) => (
                    <div key={index} className="col d-flex py-2 align-items-start">
                        <div className="icon-square d-inline-flex align-items-center justify-content-center fs-5 flex-shrink-0 me-1">
                            <i className="bi bi-quote text-warning" aria-hidden="true"></i>
                        </div>
                        <div>
                            <StyledTitle3 theme={theme}>{question}</StyledTitle3>
                            <p className="text-muted">{answer}</p>
                        </div>
                    </div>
                ))}
            </GridHelp>
        </div>
    );
};

export default Faqs;
