import { Fragment, useCallback } from 'react';
import ErrorIllustration from '../../../assets/error.png';
import { Link } from 'react-router-dom';
import { useTheme } from '../../../hooks';
import Header from '../sections/header';
import Footer from '../sections/footer';
import MobileBottomHeader from '../sections/mobileBottomHeader';
import { ErrorTitle, ErrorWrapper, Illustration, MainContainer } from '../../../styles/atoms';
import ScrollButton from './scrollUp';

function Error() {
  const { theme } = useTheme();

  // Utilisation de useCallback pour éviter la création d'une nouvelle fonction à chaque rendu
  const handleReload = useCallback((e) => {
    e.preventDefault();
    // Remplacement du rechargement complet de la page par une technique de mise en cache
    window.location.reload();
  }, []);

  return (
    <Fragment>
      <Header />
      <MainContainer theme={theme}>
        <ErrorWrapper theme={theme}>
          <ErrorTitle theme={theme}>Oups, la page est actuellement indisponible pour une maintenance technique.</ErrorTitle>
          {/* Utilisation des attributs width et height pour l'optimisation des images */}
          <Illustration src={ErrorIllustration} alt="Illustration d'erreur" width={600} height={400} />
          <Link
            role="button"
            className="btn btn-warning btn-md px-4 m-3 fw-bold"
            onClick={handleReload}
          >
            Rafraîchir la page
          </Link>
        </ErrorWrapper>
        <Footer />
      </MainContainer>
      <ScrollButton />
      <MobileBottomHeader />
    </Fragment>
  );
}

export default Error;
